import React, { RefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../modules";
import { dlog } from "../sdlib/sdutil";

type TimeSyncData = {
  onClick: () => void;
};

const NumberCanvas = styled.canvas`
  width: 80vw;
  height: 80vw;
`;

const NumberBackground = styled.div`
  width: 80vw;
  height: 80vw;
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
`;

const NumberView = styled.div`
  color: white;
  font-size: 50vw;
`;

function TimeSyncView({ onClick }: TimeSyncData) {
  const [number, setNumber] = useState(0);
  const [timestamp, setTimestamp] = useState(0);
  const canvasRef: RefObject<HTMLCanvasElement> = useRef(null);
  const { gap } = useSelector((state: RootState) => state.timesync);

  const getCurrentSecond = () => {
    let timestamp = new Date().getTime() + gap;
    setTimestamp(timestamp);
    let value = timestamp.toString()[timestamp.toString().length - 4];
    return Number(value);
  };

  const drawText = (value: number) => {
    const draw = () => {
      const is_even = value % 2 == 0;
      let canvas = canvasRef.current;
      if (canvas != null) {
        let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        ctx.fillStyle = is_even ? "black" : "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = is_even ? "white" : "black";
        ctx.font = "40vw Roboto";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(value.toString(), canvas.width / 2, canvas.height / 2);
      }
    };

    window.requestAnimationFrame(draw);
  };

  useEffect(() => {
    const onTime = setInterval(() => {
      let value = getCurrentSecond();
      if (number != value) {
        setNumber(value);
        drawText(value);
      }
    }, 1);

    return () => {
      clearInterval(onTime);
    };
  });

  return (
    <div onClick={onClick}>
      <h1>{timestamp}</h1>
      <h2>Time Gap : {gap}</h2>
      <NumberCanvas
        ref={canvasRef}
        width={document.documentElement.clientWidth * 0.8}
        height={document.documentElement.clientWidth * 0.8}
      ></NumberCanvas>
      {/* <NumberBackground
        style={{ backgroundColor: number % 2 == 0 ? "black" : "white" }}
      >
        <NumberView style={{ color: number % 2 == 0 ? "white" : "black" }}>
          {number}
        </NumberView>
      </NumberBackground> */}
    </div>
  );
}

export default TimeSyncView;
