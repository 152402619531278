import React, { RefObject, useEffect, useRef, useState } from "react";
import { startTimesync } from "../api/websocket";
import { dlog } from "../sdlib/sdutil";

let interval: any;

function SyncVideo() {
  const videoRef: RefObject<HTMLVideoElement> = useRef(null);
  const [gap, setGap] = useState(0);

  useEffect(() => {
    startTimesync().then((gap) => {
      setGap(Number(gap));
      let start_time = new Date().getTime();
      let next_time = start_time - (start_time % 10000) + 10000;
      dlog("START", start_time, next_time);

      interval = setInterval(() => {
        let time = new Date().getTime() + Number(gap);
        if (time >= next_time) {
          dlog("TT", next_time);
          next_time += 10000;

          let video_el = videoRef.current;
          if (video_el != null) {
            video_el.currentTime = 0;
          }
        }
      }, 1);
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <h1>VIDEO</h1>
      <h2>GAP: {gap}</h2>
      <video
        muted={true}
        autoPlay={true}
        webkit-playsinline="true"
        playsInline={true}
        loop={true}
        src="mdp1_1.mp4"
        ref={videoRef}
      ></video>
    </div>
  );
}

export default SyncVideo;
