const SET_CLIENT_VERSION = "version/SET_CLIENT_VERSION" as const;
const SET_SERVER_VERSION = "version/SET_SERVER_VERSION" as const;

export type VersionData = {
  client: string;
  server: string;
};

export const setClientVersion = (version: string) => ({
  type: SET_CLIENT_VERSION,
  version,
});
export const setServerVersion = (version: string) => ({
  type: SET_SERVER_VERSION,
  version,
});

const initialState: VersionData = {
  client: "",
  server: "",
};

type VersionAction =
  | ReturnType<typeof setClientVersion>
  | ReturnType<typeof setServerVersion>;

export default function version(state = initialState, action: VersionAction) {
  switch (action.type) {
    case SET_CLIENT_VERSION:
      return {
        ...state,
        client: action.version,
      };
    case SET_SERVER_VERSION:
      return {
        ...state,
        server: action.version,
      };
    default:
      return state;
  }
}
