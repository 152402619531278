import { dlog } from "../../sdlib/sdutil";

export async function startTimesync() {
  return new Promise((resolve, reject) => {
    let count = 50; // 타임싱크를 시도할 횟수
    let current_count = 0;
    let url = "ws://14.39.176.140:7007/timesync";
    let socket = new WebSocket(url);
    dlog(socket.url);
    socket.binaryType = "arraybuffer";
    socket.onopen = () => {
      // 1. 타임싱크 횟수를 전송한다
      socket.send(toByteArray(count));
    };
    socket.onmessage = (msg) => {
      // 2. 정해진 횟수만큼 시간 데이터를 전송한다
      if (current_count < count) {
        let time = new Date().getTime();
        socket.send(toByteArray(time));
        current_count++;
      } else {
        // 3. 서버로부터의 GAP를 받아온다
        let value = toLong(msg.data);
        let time_gap = Number(value) - new Date().getTime();
        resolve(time_gap);
      }
    };
    socket.onclose = () => {
      reject();
    };
    socket.onerror = () => {
      reject();
    };
  });
}

function toByteArray(long_value: number) {
  let arr = new ArrayBuffer(8);
  let dataview = new DataView(arr);
  (dataview as any).setUint64(0, long_value, true);
  return arr;
}

function toLong(byte_array: any) {
  let dataview = new DataView(byte_array);
  let value = (dataview as any).getUint64(0, true);
  return value;
}
