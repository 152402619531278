import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./pages/Main";
import SocketPage from "./pages/SocketPage";
import SyncVideo from "./pages/SyncVideo";
import Youtube from "./pages/Youtube";

function App() {
  return (
    <Switch>
      <Route path="/" component={SocketPage} exact />
      <Route path="/video" component={SyncVideo} />
      <Route path="/youtube" component={Youtube} />
    </Switch>
  );
}

export default App;
