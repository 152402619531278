import { combineReducers } from "redux";
import version from "./version";
import timesync from "./timesync";

const rootReducer = combineReducers({
  version,
  timesync,
});
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
