import React, { RefObject, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import TimeSyncView from "../components/TimeSyncView";
import { setTimeGap } from "../modules/timesync";
import { dlog, isDebug } from "../sdlib/sdutil";

const CommsLogData = styled.td`
  font-family: Consolas, Courier New, Courier, monospace;
`;

const CommsLogServer = styled.td`
  background-color: red;
  color: white;
`;

const CommsLogClient = styled.td`
  background-color: green;
  color: white;
`;

// let scheme = document.location.protocol === "https:" ? "wss" : "ws";
// let port = document.location.port ? ":" + document.location.port : "";
let scheme =
  isDebug() || document.location.protocol === "https:" ? "wss" : "ws";
let port = isDebug() ? ":" + 44368 : ":" + document.location.port;
let socket: WebSocket | null = null;

type SocketData = {
  idx: number;
  type: number;
  value: string;
};

function htmlEscape(str: any) {
  return str
    .toString()
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function SocketPage() {
  const [status, setStatus] = useState({
    sendMsg: false,
    sendBtn: false,
    closeBtn: false,
    connectionUrl: false,
    connectBtn: false,
  });
  const [state, setState] = useState("Ready to connect...");
  const tableRef: RefObject<HTMLTableSectionElement> = useRef(null);
  const dispatch = useDispatch();

  function updateState() {
    function disable() {
      setStatus({
        ...status,
        sendMsg: true,
        sendBtn: true,
        closeBtn: true,
      });
    }
    function enable() {
      setStatus({
        ...status,
        sendMsg: false,
        sendBtn: false,
        closeBtn: false,
      });
    }

    setStatus({
      ...status,
      connectionUrl: true,
      connectBtn: true,
    });

    if (!socket) {
      disable();
    } else {
      switch (socket.readyState) {
        case WebSocket.CLOSED:
          setState("Closed");
          disable();
          setStatus({
            ...status,
            connectionUrl: false,
            connectBtn: false,
          });
          break;
        case WebSocket.CLOSING:
          setState("Closing...");
          disable();
          break;
        case WebSocket.CONNECTING:
          setState("Connecting...");
          disable();
          break;
        case WebSocket.OPEN:
          setState("Open");
          enable();
          break;
        default:
          setState(
            "Unknown WebSocket State: " +
              htmlEscape(socket.readyState.toString())
          );
          disable();
          break;
      }
    }
  }

  const onShowOpenStatus = () => {
    if (tableRef.current != null) {
      tableRef.current.innerHTML +=
        "<tr>" +
        '<td colspan="3" class="commslog-data">Connection opened</td>' +
        "</tr>";
    }
  };

  const onShowCloseStatus = (event: CloseEvent) => {
    if (tableRef.current != null) {
      tableRef.current.innerHTML +=
        "<tr>" +
        '<td colspan="3" class="commslog-data">Connection closed. Code: ' +
        htmlEscape(event.code) +
        ". Reason: " +
        htmlEscape(event.reason) +
        "</td>" +
        "</tr>";
    }
  };

  const onServerToClient = (value: number) => {
    if (tableRef.current != null) {
      tableRef.current.innerHTML +=
        "<tr>" +
        '<td class="commslog-server">Server</td>' +
        '<td class="commslog-client">Client</td>' +
        '<td class="commslog-data">' +
        value +
        "</td></tr>";
    }
  };

  const onClientToServer = (value: number) => {
    if (tableRef.current != null) {
      tableRef.current.innerHTML +=
        "<tr>" +
        '<td class="commslog-client">Client</td>' +
        '<td class="commslog-server">Server</td>' +
        '<td class="commslog-data">' +
        htmlEscape(value) +
        "</td></tr>";
    }
  };

  const startWebSocket = (
    path: string,
    onopen_handler: () => void,
    onmessage: (msg: MessageEvent) => void
  ) => {
    setState("Connecting");
    socket = new WebSocket(
      scheme + "://" + document.location.hostname + port + path
    );
    socket.binaryType = "arraybuffer";
    socket.onopen = (event) => {
      updateState();
      onShowOpenStatus();

      if (onopen_handler != null) {
        onopen_handler();
      }
    };
    socket.onclose = (event) => {
      updateState();
      onShowCloseStatus(event);
    };
    socket.onerror = updateState;
    socket.onmessage = (msg) => {
      if (onmessage != null) {
        onmessage(msg);
      }
    };
  };

  const receiveServerTime = () => {
    startWebSocket(
      "/get_server_time",
      () => {},
      (msg) => {
        let dataview = new DataView(msg.data);
        let answer = (dataview as any).getUint64(0, true);

        onServerToClient(answer);
      }
    );
  };

  const onClickPing = (count: number) => {
    let current_count = 0;
    startWebSocket(
      `/ping?count=${count}`,
      () => {
        let time = new Date().getTime();
        sendData(time);
      },
      (msg) => {
        let dataview = new DataView(msg.data);
        let answer = (dataview as any).getUint64(0, true);
        onServerToClient(answer);

        if (current_count < count - 1) {
          let time = new Date().getTime();
          sendData(time);
          current_count++;
        }
      }
    );
  };

  const sendData = useCallback((value: number) => {
    sendDataToServer(value);
    onClientToServer(value);
  }, []);

  const sendDataToServer = useCallback((value: number) => {
    let arr = new ArrayBuffer(8);
    let dataView = new DataView(arr);
    (dataView as any).setUint64(0, value, true);
    socket?.send(arr);
  }, []);

  const onClickTimesync = () => {
    let try_count = 50;
    let current_count = 0;
    startWebSocket(
      "/timesync",
      () => {
        sendDataToServer(try_count);
      },
      (msg) => {
        let dataview = new DataView(msg.data);
        let answer = (dataview as any).getUint64(0, true);

        if (current_count < try_count) {
          let time = new Date().getTime();
          sendDataToServer(time);
          dlog(current_count, time);
          current_count++;
        } else {
          let time_gap = Number(answer) - new Date().getTime();
          dispatch(setTimeGap(time_gap));
          sendDataToServer(time_gap);
        }
      }
    );
  };

  return (
    <>
      <h1>I M FINE WebSocket</h1>
      <div>Version({process.env.REACT_APP_VERSION_CODE})</div>
      <p id="stateLabel">{state}</p>
      <button onClick={receiveServerTime}>RECEIVE SERVER TIME</button>
      <button onClick={() => onClickPing(5)}>PING (5 times)</button>
      <button onClick={() => onClickPing(10)}>PING (10 times)</button>
      <button onClick={onClickTimesync}>Time Sync</button>

      <TimeSyncView onClick={onClickTimesync} />

      <h2>Communication Log</h2>
      <table style={{ width: "800px" }}>
        <thead>
          <tr>
            <td style={{ width: "100px" }}>From</td>
            <td style={{ width: "100px" }}>To</td>
            <td>Data</td>
          </tr>
        </thead>
        <tbody ref={tableRef}></tbody>
      </table>
    </>
  );
}

export default SocketPage;
