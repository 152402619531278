import React, { RefObject, useEffect, useRef, useState } from "react";
import { dlog } from "../sdlib/sdutil";

let player: any;
function Youtube() {
  const playerRef: RefObject<HTMLDivElement> = useRef(null);
  const [time, setTime] = useState(0);

  const onPlayerReady = () => {
    player.mute();
    player.playVideo();
  };

  const onPlayerStateChange = () => {
    setTimeout(() => {
      setTime(player.getCurrentTime());
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      let YT = (window as any).YT;
      if (YT.Player !== undefined) {
        player = new YT.Player("player", {
          height: "360",
          width: "640",
          videoId: "dk_cOqEqSo0",
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
        (window as any).player = player;
        dlog(player);
      }
    }, 100);

    return () => {};
  }, []);

  const omUnmute = () => {
    player.unMute();
  };

  const onSeek = () => {
    let current_time = player.getCurrentTime();
    let time = current_time - 5000;
    player.seekTo(time, true);
  };

  const onLive = () => {
    player.seekTo(10000000000000000, true);
  };

  return (
    <div>
      <h1>Youtube Live</h1>
      <div id="player"></div>

      <div>current_time: {time}</div>

      <button onClick={omUnmute}>UNMUTE</button>
      <button onClick={onSeek}>SEEK</button>
      <button onClick={onLive}>LIVE</button>
    </div>
  );
}

export default Youtube;
