const SET_TIME_GAP = "timesync/SET_TIME_GAP" as const;

export type TimesyncData = {
  gap: number;
};

export const setTimeGap = (gap: number) => ({
  type: SET_TIME_GAP,
  gap,
});

const initialState: TimesyncData = {
  gap: 0,
};

type VersionAction = ReturnType<typeof setTimeGap>;

export default function version(state = initialState, action: VersionAction) {
  switch (action.type) {
    case SET_TIME_GAP:
      return {
        ...state,
        gap: action.gap,
      };
    default:
      return state;
  }
}
